import React from 'react'
import { Link } from 'gatsby'

import Cookie from '../../../components/mainComponents/Cookies/Cookies'

import * as styles from '../../../components/mainComponents/TermsConditions/styles.module.scss'
import Seo from '../../../components/Seo'

const Cookies = () => (
  <>
    <Seo
      title="Justice | Cookies"
      htmlAttributes={{
        lang: 'en',
      }}
      url="https://www.it-justice.com/"
      description="Justice IT Cookies Policy"
    />
    <div>
      <div className={styles.headerContainer}>
        <h1 className={styles.headerContainerH1}>Terms & conditions</h1>
      </div>
      <div className={styles.headerContainerBloks}>
        <div className={styles.headerContainerBloksLinks}>
          <Link
            to="/terms-and-conditions/privacy-policy"
            className={styles.headerContainerBloksLinksTo}
            activeClassName={styles.headerContainerBloksLinksToActive}
          >
            privacy policy
          </Link>
          <Link
            to="/terms-and-conditions/cookies"
            className={styles.headerContainerBloksLinksTo}
            activeClassName={styles.headerContainerBloksLinksToActive}
          >
            cookies
          </Link>
        </div>
        <div className={styles.headerContainerBloksContent}>
          <div>
            <Cookie />
          </div>
        </div>
      </div>
    </div>
  </>
)

export default React.memo(Cookies)
