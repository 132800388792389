import React from 'react'

import * as styles from './styles.module.scss'

const Cookie = () => (
  <div className={styles.cookieBlock}>
    <h2>Cookies</h2>
    <p className={styles.cookieBlockText}>
      This website may use cookies to collect information about you. If you use
      this website, then you accept that we can use the cookies set out below.
    </p>
    <p className={styles.cookieBlockSubTitle}>What are cookies?</p>
    <p className={styles.cookieBlockText}>
      Cookies are small data files which are placed on your computer by this
      website and which collect certain personal information about you.
    </p>
    <p className={styles.cookieBlockSubTitle}>Why do we use cookies?</p>
    <p className={styles.cookieBlockText}>
      Cookies enable us to tailor our service offering (including the website)
      and to provide you with a better service.
    </p>
    <p className={styles.cookieBlockSubTitle}>What cookies do we use?</p>
    <p className={styles.cookieBlockText}>
      We use the following cookies:
      <ul>
        <li className={styles.cookieBlockText}>
          Strictly necessary cookies. These are cookies that are required for
          the operation of our website. They include, for example, cookies that
          enable you to log into secure areas of our website. They do not gather
          information about you that could be used for marketing purposes or
          remembering where you’ve been on the Internet.
        </li>
        <li className={styles.cookieBlockText}>
          Analytical/performance cookies. They allow us to collect information
          about how you use our website, such as, how you move around our
          website and if you experience any errors. These cookies do not collect
          any information that could identify you; all of the information
          collected is anonymous and is only used to help us improve the way our
          website works, understand what interests our users and measure how
          effective our advertising is.
        </li>
        <li className={styles.cookieBlockText}>
          Functionality cookies. These are used to recognise you when you return
          to our website. This enables us to personalise our content for you,
          greet you by name and remember your preferences and improve your
          visit.
        </li>
      </ul>
      <p className={styles.cookieBlockText}>
        Generally, cookies which are strictly necessary for the operation of the
        website will expire when you leave the website. Other cookies may be
        more permanent or not expire unless you actively delete them.
      </p>
      <p className={styles.cookieBlockSubTitle}>How to reject cookies</p>
      <p className={styles.cookieBlockText}>
        If you want to reject any of the cookies that we use, then you will need
        to change your website browser settings.
      </p>
      <p className={styles.cookieBlockSubTitle}>Security</p>
      <p className={styles.cookieBlockText}>
        Whilst we take appropriate technical and organisational measures to
        safeguard the personal information that you provide to us, we accept no
        liability if communications are intercepted by third parties or
        incorrectly delivered or not delivered.
      </p>
      <p className={styles.cookieBlockSubTitle}>Opt-out terms</p>
      <p className={styles.cookieBlockText}>
        You may “opt-out” of receiving promotional emails from LendInvest
        Limited by following the instructions in those emails. You may also send
        requests relating to promotional messages and your permission for
        sharing information with third parties for our operational or their
        marketing purposes by emailing{' '}
        <a href="mailto:hello@it-justice.com">hello@it-justice.com</a>. Opt-out
        requests will not apply to transactional service messages, including
        messages about your current LendInvest Limited services.
      </p>
    </p>
  </div>
)

export default React.memo(Cookie)
